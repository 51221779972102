@import '../../styles/variables';

.wrapper {
  margin-right: 20px;

  h2 {
    font-size: 16px;
  }

  .container {
    > ul:first-child {
      display: flex;
      align-items: center;
      justify-content: center;

      li {
        padding: 18px 5px;
        margin: 0;

        &:not(:nth-child(2)) {
          text-decoration: underline;
          color: $color-blue-00;
          cursor: pointer;
        }
      }
    }

    .form {
      position: relative;

      input {
        padding-right: 50px;
      }

      img {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        cursor: normal;

        &.close {
          right: 35px;

          &:hover {
            cursor: pointer;
            opacity: 0.8;
          }
        }

        &.search {
          right: 8px;
        }
      }
    }

    .no-value-selected {
      text-align: center;
      color: $bg-danger;
      font-weight: 700;
      background: #{$bg-danger}22;
      border-radius: 5px;
      padding: 15px;
      margin-bottom: 15px;
    }

    .values {
      height: 25vh;
      overflow-y: auto;
      position: relative;

      li {
        padding-left: 25px;
        position: relative;
        cursor: pointer;

        &:hover {
          background: $color-grey-E4;
        }

        &.selected::before {
          content: '\2713';
          position: absolute;
          left: 3px;
          color: green;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }

    @media (max-height: 768px) {
      .values {
        height: 10vh;
        li {
          padding: 5px 5px 5px 25px;
        }
      }
    }
  }
}

// Can be used for implementing multiple Filter by using summary and toggeling similar to google docs
// details {
//   > summary {
//     position: relative;

//     &::before {
//       transition: all 0.05s linear;
//       content: '';
//       display: block;
//       height: 6px;
//       position: absolute;
//       width: 0;
//       height: 0;
//       border-top: 6px solid transparent;
//       border-bottom: 6px solid transparent;
//       border-left: 6px solid $color-grey-32;
//       left: -20px;
//       top: 50%;
//       transform: translateY(-50%);
//       opacity: 0.6;
//     }

//   }

//   &[open] summary::before {
//     margin-top: 2px;
//     border-left: 6px solid transparent;
//     border-right: 6px solid transparent;
//     border-top: 6px solid $color-grey-32;
//   }
// }
