@import '../../../../styles/variables';

.wrapper {
  margin-top: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #d2d5e2;
  position: relative;

  label {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  p {
    position: relative;
    top: -5px;
    padding: 3px;
    border: 1px solid #{$bg-danger}66;
    border-radius: 3px;
  }
}