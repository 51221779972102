@import '../../styles/variables';

.wrapper {
  border-left: 1px solid #d2d5e2;
  height: calc(100% - 50px);

  > * {
    padding: 12px 15px;
    background: $color-white;
  }

  .header {
    border-bottom: 1px solid #d2d5e2;
    height: 50px;

    h2 {
      font-size: 20px;
      line-height: 134.4%;
      font-weight: 500;
    }
  }
}
