body {
  min-width: 320px;
  background: $color-grey-F3;
  font-family: $font-roboto;
  font-size: $font-size-base;
  line-height: $line-height-base;
  color: $color-grey-32;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-size-adjust: none;
}

/* Scroll bar */
/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: lighten($color-purple-92, 15%);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $color-purple-92;
}

a {
  color: inherit;
  text-decoration: none;

  &:hover,
  &[href^='tel'] {
    text-decoration: none;
  }

  button & {
    text-decoration: none;
  }
}

button:disabled {
  opacity: 0.7;
}

ul {
  list-style: none;
  margin-bottom: 0;
}
