@import '../../styles/variables';

.wrapper {
  max-width: 100%;
  position: fixed;
  top: $topnav-height;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: auto;
  background: $color-white;

  > section {
    display: grid;
    grid-template-columns: 1fr 423px;
  }
}
