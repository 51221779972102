@import '../../styles/variables';

.wrapper {
  height: $fixed-layout-height;
  overflow: hidden;
  background: $color-white;

  .header {
    display: flex;
    align-items: center;
    padding: 20px 20px 0;
    line-height: 134.4%;
    border-bottom: 1px solid #d2d5e2;

    h1 {
      font-size: 20px;
      margin-right: 60px;
      font-weight: 500;
      max-width: 220px;
      text-overflow: ellipsis;
      overflow: hidden;
      cursor: default;
    }

    ul {
      display: flex;
      align-items: center;
      gap: 30px;
      font-size: 16px;

      li {
        padding: 0 4px 18px;
        box-sizing: border-box;
        cursor: pointer;
        position: relative;
      }

      .active {
        font-weight: 500;

        &:after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          background: $color-grey-D2;
          height: 8px;
          border-radius: 20px 20px 0 0;
        }
      }
    }
  }

  .tab {
    display: none;

    &.active {
      display: block;
    }
  }
}
