@import '../../../../styles/variables';

.wrapper {
  border: 1px solid $color-grey-32;
  padding: 15px;
  position: relative;

  .buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
  }
}