@import '../../styles/variables';

.wrapper {
  display: flex;
  flex-direction: column;
  background: $color-grey-F3;

  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    line-height: 134.4%;
    height: 60px;
    padding-left: 20px;
    padding-right: 20px;
    border-bottom: 1px solid #d2d5e2;
    background: $color-white;
  }

  .chatDisplayWrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    height: calc(100vh - #{$topnav-height} - 60px);
  }
}
