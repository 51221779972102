@import '../../styles/variables';

.message {
  margin-bottom: 20px;

  &.aborted,
  &.user,
  &.agent {
    margin-left: auto;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
  }

  .text {
    background-color: $color-white;
    border: 1px solid #d2d5e2;
    box-sizing: border-box;
    padding: 10px 14px;
    font-size: 16px;
    line-height: 134.4%;
    border-radius: 0 12px 12px 12px;
    position: relative;
    display: inline-block;
    overflow-wrap: break-word;
    max-width: 100%;
  }

  .flaggable {
    // &:hover {
    //   cursor: pointer;
    // }

    .text.hovered:before {
      content: '';
      width: 17px;
      height: 20px;
      background: url('../../assets/images/flag-icon.svg');
      position: absolute;
      left: -25px;
      top: 50%;
      transform: translateY(-50%);
    }

    .text.flagged:before {
      content: '';
      width: 17px;
      height: 20px;
      background: url('../../assets/images/flag-icon.svg');
      position: absolute;
      left: -25px;
      top: 50%;
      transform: translateY(-50%);
      filter: invert(43%) sepia(91%) saturate(1485%) hue-rotate(331deg) brightness(99%) contrast(86%);
    }
  }

  &.agent {
    .text {
      background: $color-blue-00;
      color: $color-white;
      margin-left: auto;
      border-radius: 12px 0 12px 12px;
    }
  }

  &.user {
    .text {
      background: $color-cream-F2;
      color: $color-white;
      margin-left: auto;
      border-radius: 12px 0 12px 12px;
    }
  }

  &.aborted {
    .text {
      background: darken($bg-danger, 20%);
      color: $color-white;
      border-radius: 12px 0 12px 12px;
      opacity: 0.5;
      text-decoration: line-through;
      text-decoration-thickness: 0.1px;
    }
  }

  &.pending {
    .text {
      background: $color-purple-92;
      color: $color-white;
      border-radius: 12px 0 12px 12px;
    }
  }

  &.dtmf {
    .text {
      background: $color-blue-61;
      color: $color-white;
      margin-left: auto;
      border-radius: 12px 0 12px 12px;
    }
  }

  p {
    font-size: 14px;
    line-height: 134.4%;
    color: $color-purple-92;
    margin-bottom: 3px;
    padding: 0 12px;
  }

  .text {
    &.text-intent {
      font-style: italic;
      background: lighten($color-blue-00, 25%);
    }
  }
}
