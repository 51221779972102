@import '../../styles/variables';

.wrapper {
  z-index: 1;
  background: $color-grey-32;
  color: rgba(255, 255, 255, 0.56);
  font-size: 16px;
  line-height: 134.4%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  position: fixed;
  left: 0;
  right: 0;
  width: 100%;

  ul {
    display: flex;
    align-items: center;
    margin-bottom: 0;

    li {
      cursor: pointer;

      &.brand {
        display: flex;
        align-items: center;
        margin-right: 30px;

        svg {
          margin-left: 10px;
          position: relative;
          top: 2px;
        }
      }
    }

    &.pages li:not(:first-child) {
      position: relative;

      a {
        display: flex;
        align-items: center;
        gap: 6px;
        padding: 20px;

        &:hover,
        &.active {
          color: $color-white;

          img {
            filter: brightness(0) invert(1);
          }
        }

        &.active:after {
          content: '';
          position: absolute;
          bottom: -0.2px;
          left: 5px;
          right: 5px;
          height: 6px;
          background: $color-purple-92;
          border-radius: 5px 5px 0 0;
        }
      }
    }
  }

  .pages li a,
  .account li:not(:last-child) {
    padding: 0 14px;
  }

  .account {
    position: relative;

    li {
      min-width: 16px;

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }
    }
  }
}
