@import '../../styles/variables';

.scrollBottom {
  position: absolute;
  bottom: 10px;
  border-radius: 50px;
  left: 50%;
  transform: translateX(-50%);
  background: $color-grey-32;
  padding: 12px 18px;
  color: $color-white;
  font-size: 16px;
  line-height: 134.4%;
  opacity: 0.6;
  animation: MoveUpDown 1s linear 1;
  transition: all 0.4s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    margin-right: 6px;
  }

  &:hover {
    opacity: 1;
    transform: translateX(-50%);
    box-shadow: 0 4px 4px #888888;
    transition: color 0.3s, background-image 0.5s, ease-in-out;
  }
}

@keyframes MoveUpDown {
  0%,
  100% {
    opacity: 0.6;
    transform: translateY(0) translateX(-50%);
    box-shadow: 0 4px 4px #888888;
    transition: none;
  }
  50% {
    box-shadow: 0 4px 4px #888888;
    transition: color 0.3s, background-image 0.5s, ease-in-out;
    opacity: 1;
    transform: translateY(4px) translateX(-50%);
  }
}