@import '../../styles/variables';

.wrapper {
  position: relative;

  span {
    display: inline-block;
    width: 20px;
  }

  .dropdown {
    color: $color-white;
    background: lighten($color-grey-32, 2%);
    position: absolute;
    top: 22px;
    right: -8px;
    width: 230px;
    padding: 15px;
    border-radius: 5px;
    box-shadow: 0px 0px 9px 3px rgba(0, 0, 0, 0.15);

    ul {
      display: block;
      text-align: right;
      font-weight: 500;

      li {
        display: flex;
        align-items: center;
        gap: 5px;
        justify-content: flex-end;

        &:hover {
          color: darken($color-white, 15%);
        }
      }
    }
  }
}
