@import '../../styles/variables';

.wrapper {
  font-size: 16px;
  line-height: 134.4%;
  color: $color-purple-92;
  position: relative;

  summary {
    cursor: pointer;
  
    h3 {
      font-size: 16px;
      line-height: 134.4%;
      padding: 13px 28px 0;
      color: $color-purple-92;

      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  ul {
    padding: 14px 28px 16px;
    border-bottom: 1px solid #D2D5E2;

    li {
      color: $color-grey-32;
      padding-top: 11px;
      padding-bottom: 11px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      span {
        font-weight: normal;
      }
    }
  }
}