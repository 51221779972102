@import '../../../../styles/variables';

.wrapper {
  z-index: 0;
  display: block;
  position: relative;
  min-width: 35px;

  > span {
    transition: all 0.3s linear;
    border-radius: 5px;
    opacity: 0.35;
    cursor: pointer;
    padding: 5px;
    position: relative;
    top: -2px;

    &:hover {
      opacity: 0.8;
      background: lighten($color-purple-92, 25%);
    }

    &.active {
      opacity: 0.8;
      background: lighten($color-purple-92, 20%);
    }

    &.has-filter {
      opacity: 0.8;
      background: $color-white;
    }
  }

  .active-sort-label {
    position: absolute;
    right: -7px;
    bottom: -3px;
    background: $color-white;
    border-radius: 50px;
    box-shadow: 0px 5px 5.5px rgba(0, 0, 0, 0.1);

    > img {
      position: relative;
      margin: 2px;
    }
  }

  .modal {
    z-index: 1;
    position: absolute;
    top: 32px;
    left: 0px;

    &.left {
      left: -320px;
    }
  }
}
