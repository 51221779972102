@import '../../styles/variables';

.label {
  width: 81px;
  height: 30px;
  text-align: center;
  display: block;
  border-radius: 10px;
  color: #fff;
  padding-top: 7px;
  padding-bottom: 7px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;

  &.label-pending {
    background: $bg-primary;
  }

  &.label-closed {
    background: $bg-success;
  }

  &.label-active {
    background: $bg-danger;
  }

  &.label-invalid {
    background: $bg-white;
    color: #565656;
  }

  &.label-scheduled {
    background: $color-blue-1C;
  }

  &.label-removed {
    background: $color-white;
    color: $color-purple-92;
    border: 1px solid $color-purple-92;
  }
}
