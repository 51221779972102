@import '../../../../styles/variables';

.wrapper {
  .interventions {
    height: 350px;
  }

  form {
    padding-bottom: 60px;
    border-bottom: 1px solid $color-grey-D2;

    textarea {
      width: 100%;
      height: 300px;
      resize: none;
      padding-bottom: 20px;
    }
  }
}
