@import '../../../../styles/variables';

.wrapper {
  width: 400px;
  padding: 5px 20px;
  border: 2px solid $color-grey-D2;
  border-radius: 5px;
  margin: 5px;
  background: $color-white;
  position: relative;

  summary {
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.6;

    &:hover {
      opacity: 0.9;
      cursor: pointer;
    }
  }

  &[open] {
    padding: 15px 20px;
  }

  form {
    padding: 15px;
    max-width: 100%;

    .buttons {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 30px;
    }
  }
}
