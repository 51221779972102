@import '../../../../styles/variables';

.claim {
  font-size: 16px;
  line-height: 134.4%;
  color: $color-purple-92;

  summary {
    cursor: pointer;

    h3 {
      font-size: 16px;
      line-height: 134.4%;
      padding: 13px 28px;
      color: $color-purple-92;

      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  ul {
    padding: 0 28px 16px;
    border-bottom: 1px solid $color-grey-D2;

    li > div:first-child {
      span,
      strong {
        max-width: 50%;
      }

      span {
        text-align: right;
        word-break: break-word;
      }
    }

    &.intervention-mode {
      li > div {
        cursor: pointer;

        & span {
          position: relative;

          &:not(.unavailable):before {
            content: '';
            width: 20px;
            height: 22px;
            top: 50%;
            transform: translateY(-50%);
            background-size: stretch;
            display: block;
            position: absolute;
            left: -23px;
            background: $bg-secondary;
            -webkit-mask-image: url('../../../../assets/images/add_info_icon.svg');
            mask-image: url('../../../../assets/images/add_info_icon.svg');
            mask-repeat: no-repeat;
          }
        }
      }
    }

    li {
      color: $color-grey-32;
      padding-top: 11px;
      padding-bottom: 11px;

      > div {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &.previous-value {
          margin-top: 6px;
          color: $color-purple-92;

          img {
            position: relative;
            bottom: 5px;
            left: 3px;
            margin-right: 6px;
          }
        }
      }

      span {
        font-weight: normal;

        &.unavailable {
          cursor: default;
          color: $color-grey-32;
          opacity: 0.45;
        }
      }
    }
  }
}

.buttonContainer {
  margin-top: 18px;
  text-align: center;
  padding: 14px 28px 16px;
}
