@import '../../../../styles/variables';

.wrapper {
  cursor: pointer;
  text-align: right;
  color: $bg-secondary;

  &:hover {
    text-decoration: underline;
  }
}
