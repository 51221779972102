@import '../../../../styles/variables';

.callActions {
  padding: 16px;
  margin-right: 13px;
  border: 1px solid #d2d5e2;
  border-radius: 5px;
  background: $color-white;

  .statusContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 14px;
    height: 30px;

    > span {
      flex: 1;
    }

    > span:nth-child(2) {
      flex: 1.5;
    }

    .status {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      gap: 5px;
      font-size: 16px;
      line-height: 19px;
      margin-left: 6px;

      &.prep {
        color: $bg-secondary;
      }

      &.ongoing {
        color: $bg-success;
      }

      &.disconnected {
        color: $bg-danger;
      }

      &.editing {
        color: $bg-secondary;
      }

      img {
        margin-right: 10px;
      }
    }

    .confidence {
      color: $color-white;
      padding: 3px 11px;
      border-radius: 10px;

      &.low {
        background: $bg-danger;
      }

      &.medium {
        background: $bg-secondary;
      }

      &.high {
        background: $bg-success;
      }
    }
  }

  .textareaContainer {
    width: 100%;
    height: 88px;
    max-height: 88px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: stretch;

    .loader {
      width: 100%;
      height: 100%;
      position: relative;

      &.prep:before {
        content: '';
        position: absolute;
        top: 0.5px;
        left: 0;
        right: 0;
        width: 100%;
        height: 3px;
        background: rgba(235, 87, 87, 0.43);
        border-radius: 5px 5px 0 0;
      }

      &.prep:after {
        content: '';
        position: absolute;
        top: 0.5px;
        left: 0;
        right: 0;
        //TODO Convert this to use a styled component depending on the executionConfig confidecenThreshold item delay
        animation: loadingBar 2s linear;
        height: 3px;
        background: $bg-danger;
        border-radius: 5px 5px 0 0;
      }
    }

    textarea {
      width: 100%;
      height: 100%;
      border: 1px solid #d2d5e2;
      border-radius: 5px;
      resize: none;
      padding: 12px;
      font-size: 16px;
      line-height: 134.4%;
      position: relative;
    }

    .keyboard {
      margin-left: 15px;
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      grid-template-rows: 1fr 1fr;
      gap: 10px;

      button {
        box-shadow: 0px 2px 3.5px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        height: 35px;
        width: 35px;

        &:active {
          box-shadow: none;
        }
      }
    }
  }

  .actions {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;

    div:first-child {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }

    button {
      margin-top: 13px;
      width: 165px;
      font-size: 16px;
      line-height: 19px;
      padding-left: 15px;
      padding-right: 15px;

      &:not(:last-child) {
        margin-right: 10px;
      }

      img {
        margin-right: 10px;
      }

      @media (max-width: 1270px) {
        width: auto;
        font-size: 14px;

        &:not(:last-child) {
          margin-right: 5px;
        }

        img {
          margin-right: 5px;
        }
      }
    }
  }
}

@keyframes loadingBar {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}
