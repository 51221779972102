@import '../../../../styles/variables';

.wrapper {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  justify-content: space-between;
  font-weight: 400;
  font-size: 16px;
  line-height: 134.4%;
  background: $color-white;
  padding-right: 10px;
  padding-left: 20px;
  height: 50px;
  border-bottom: 1px solid $color-grey-D2;

  h1 {
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    margin: 15px 33px;
  }

  ul,
  .container {
    display: flex;
    align-items: center;
    gap: 18px;
  }

  ul li {
    padding: 15px;
    position: relative;
    cursor: pointer;

    &.active {
      font-weight: 500;

      &:after {
        content: '';
        height: 8px;
        background: $color-grey-D2;
        position: absolute;
        bottom: 0;
        left: 5px;
        right: 5px;
        border-radius: 5px 5px 0 0;
      }
    }
  }

  .filters {
    font-weight: normal;
    color: $color-purple-92;
    justify-content: flex-end;

    div {
      cursor: pointer;
      display: flex;
      align-items: center;

      img {
        margin-right: 10px;
      }

      &.search {
        background: $color-grey-F3;
        border-radius: 5px;
        padding: 8px 12px;

        input {
          background: inherit;
        }
      }
    }
  }
}
