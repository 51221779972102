@import '../../styles/variables';


.wrapper {
  max-height: 100vh;
  overflow: hidden;

  main {
    display: flex;
  }
}