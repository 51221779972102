@import '../../styles/variables';

.info {
  label {
    margin-bottom: 8px;
    display: block;
  }

  .formControl {
    border: 1px solid #d2d5e2;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 12px 15px;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &.focused {
      border: 1px solid #007bc0;

      .updateSlot {
        color: #007bc0;
        font-size: 16px;
        line-height: 134.4%;
        cursor: pointer;
        z-index: 9999;
      }
    }

    input {
      flex-grow: 1;
      padding-right: 10px;

      &.updating {
        color: $color-blue-00;
      }
    }
  }

  &:last-child .formControl {
    margin-bottom: 0;
  }

  &.fadeIn {
    animation-name: fadeInAnim;
    animation-duration: 0.7s;
  }

  p {
    padding: 13px 28px 0;
    text-align: center;

    img {
      margin-left: 10px;
    }
  }
}

@keyframes fadeInAnim {
  0% {
    opacity: 0;
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
