@import '../../styles/variables';

.wrapper {
  display: grid;
  grid-template-columns: minmax(250px, 423px) 1fr;
  // 60px is the header height
  height: calc(#{$fixed-layout-height} - 60px);

  .call-summary {
    border-right: 1px solid $color-grey-D2;
    overflow-y: auto;

    > * {
      padding: 20px;
    }
  }
}
