@import '../../../../styles/variables';

.chatDisplay {
  padding-right: 5px;
  font-weight: normal;
  overflow: hidden;
  height: auto;
  padding-bottom: 10px;
  padding-top: 10px;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 20px;
  -webkit-flex-grow: 1;
  -webkit-flex-shrink: 1;
  overflow-y: auto;
  padding-left: 15px;
  background: inherit;

  .issueLabel {
    color: $bg-danger;
    font-weight: normal;
    font-size: 16px;
    background: inherit;
    width: 100%;
    text-align: center;
    border-bottom: 1px solid $bg-danger;
    line-height: 0.1em;
    margin: 50px auto;

    span {
      background: $color-grey-F3;
      padding: 0 10px;
    }
  }
}
