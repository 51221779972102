@import '../../../../styles/variables';

.wrapper {
  width: 400px;
  padding: 15px 10px 15px 20px;
  border: 2px solid $color-grey-D2;
  border-radius: 5px;
  margin: 5px;
  position: relative;

  > * {
    transition: all 0.1s linear;
  }

  &.not-visible {
    border: 1px solid $color-grey-D2;
  }

  input,
  select {
    max-width: 100%;
  }

  summary {
    font-weight: 500;
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
      max-width: 70%;
    }

    &.not-visible {
      opacity: 0.4;
    }

    &::before {
      transition: all 0.05s linear;
      content: '';
      display: block;
      height: 6px;
      position: absolute;
      width: 0;
      height: 0;
      border-top: 6px solid transparent;
      border-bottom: 6px solid transparent;
      border-left: 6px solid $color-grey-32;
      left: -12px;
      top: 50%;
      transform: translateY(-50%);
      opacity: 0.3;
    }

    .actions {
      flex-wrap: nowrap;
      display: flex;
      align-items: center;

      span {
        opacity: 0.5;
        padding: 0 3px;

        &:hover {
          opacity: 0.725;
        }

        &.drag {
          margin-left: 8px;
          cursor: grab;
        }
      }

      > img {
        margin: 0 3px;
      }
    }
  }

  &[open] {
    background: $color-white;

    summary {
      padding-bottom: 15px;

      &.not-visible {
        opacity: 1;
      }

      &::before {
        opacity: 0.6;
        margin-top: -3px;
        left: -14px;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 6px solid $color-grey-32;
      }
    }
  }

  form {
    margin-top: 15px;
    border: none;
    padding: 0;
  }
}
