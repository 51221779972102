@import '../../../../styles/variables';

.wrapper {
  h3 {
    font-size: 16px;
    font-weight: 500;
  }

  .actions {
    display: flex;

    > button {
      &:not(:first-child) {
        margin-left: 10px;
      }
    }
  }
}
