@import '../../../../styles/variables';

%clipboard-display {
  content: '';
  display: inline-block;
  background: url('/assets/images/clipboard.svg');
  background-repeat: no-repeat;
  background-size: cover;
  width: 23px;
  height: 23px;
  position: absolute;
  top: 50%;
  transform: translateY(-55%);
  left: -22px;
}

.wrapper {
  position: relative;
  min-height: 13rem;
  text-transform: capitalize;
  border-bottom: 1px solid $color-grey-D2;

  .header {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin-bottom: 30px;
    position: relative;

    > li > span {
      font-size: 24px;
      font-weight: bold;
      position: relative;
      cursor: pointer;

      &:not(.hideClipboardIcon):hover:before {
        @extend %clipboard-display;
      }
    }

    .menu {
      position: absolute;
      right: 0;
      text-transform: none;

      button {
        background: $color-white;
      }

      svg {
        width: 30px;
      }
    }
  }

  .details {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 5px 45px;

    > p {
      display: flex;
      justify-content: space-between;

      > span:first-child {
        flex-basis: 66%;
      }

      > span[data-tip] {
        font-weight: 500;
        cursor: pointer;
        position: relative;
        align-self: flex-start;

        &:not(.hideClipboardIcon):hover:before {
          @extend %clipboard-display;
        }
      }
    }
  }
}
