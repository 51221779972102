@import '../../styles/variables';

.textarea-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  > textarea {
    padding-top: 20px;
    flex: 1;
    width: 100%;
    resize: none;

    &.hasError {
      box-shadow: $box-shadow-danger;
    }
  }

  > .label {
    position: absolute;
    top: 13px;
    right: 27px;
    font-weight: 400;
    font-style: italic;
    color: $color-purple-92;
  }
}
