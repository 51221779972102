.mute {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-right: 7px;
  font-size: 14px;
  font-weight: 500;

  svg {
    width: 20px;
    height: 20px;
  }
}