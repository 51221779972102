@import '../../styles/variables';

.wrapper {
  color: $bg-danger;
  font-weight: normal;
  font-size: 16px;
  background: inherit;
  width: 100%;
  text-align: center;
  border-bottom: 1px solid $bg-danger;
  line-height: 0.1em;
  margin: 50px auto;

  span {
    background: $color-grey-F3;
    padding: 0 10px;
  }

  .scrollToSpan {
    position: relative;
    top: 180px;
    background: transparent;
  }
}
