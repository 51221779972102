@import '../../../../styles/variables';

.wrapper {
  margin: 10px;
  border: 1px solid $color-grey-D2;
  padding: 0 0 15px;
  
  h3 {
    background: $color-white;
    padding: 15px 20px;
    border-bottom: 1px solid $color-grey-D2;
    width: 100%;
  }
  
  .fields {
    position: relative;
    padding: 8px 0;
    overflow-y: auto;
    height: calc(100% - 55px);
  }
}
