@import '../../../../styles/variables';

.wrapper {
  overflow: auto;

  .no-interventions {
    position: relative;
    height: calc(#{$fixed-layout-height} - 59px);
  }

  .intervention-form-container {
    border: 1px solid red;
    display: block;
    position: relative;
    height: calc(#{$fixed-layout-height} - 59px);
  }
}
