@import '../../styles/variables';

.wrapper {
  display: grid;
  grid-template-columns: 75vw 25vw;
  height: 100%;

  > section {
    border-right: 1px solid #d2d5e2;
    position: relative;
  }
}

@media screen and (max-width: 1400px) {
  .wrapper {
    grid-template-columns: 70vw 30vw;
  }
}

@media screen and (max-width: 1200px) {
  .wrapper {
    grid-template-columns: 60vw 40vw;
  }
}
