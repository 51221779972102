.btn {
  display: inline-block;
  appearance: none;
  border: none;
  border-radius: 5px;
  background: transparent;
  color: $color-white;
  text-decoration: none;
  padding: 12px 27px;
  transition: color 0.3s, background 0.3s;
  text-align: center;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;

  &:hover {
    opacity: 0.8;
  }

  &.btn-primary {
    background: $bg-primary;
  }

  &.btn-danger {
    background: $bg-danger;
  }

  &.btn-secondary {
    background: $bg-secondary;
  }

  &.btn-success {
    background: $bg-success;
  }

  &.btn-loading {
    background: rgba(50, 50, 93, 0.56);
  }

  &.btn-invalid {
    background: $bg-white;
    font-weight: bold;
    color: #363636;
  }

  &.btn-ternary {
    background: $color-purple-92;
  }

  &.btn-ternary-reverse {
    color: $color-purple-92;
    border: 1px solid $color-purple-92;
    padding: 10px 25px;
  }

  &.btn-full-width {
    width: 100%;
  }

  &.btn-md {
    padding-left: 5px;
    padding-right: 5px;
    width: 180px;
  }

  &.btn-sm {
    width: 140px;
  }

  &.btn-xs {
    padding: 0;
  }

  &.btn-with-icon {
    display: flex;
    align-items: center;
    gap: 5px;

    span {
      align-self: center;
      flex: 1;
    }
  }

  &.btn-just-icon {
    width: auto !important;

    img {
      margin: 0 !important;
    }
  }

  &.btn-loading {
    background: rgba(50, 50, 93, 0.56);
    pointer-events: none;
  }

  &.has-error {
    background: $bg-danger;
  }
}
