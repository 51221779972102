@import '../../../../styles/variables';

.wrapper {
  background: $color-white;
  padding: 3px;
  border-radius: 5px;
  border: 1px solid #d2d5e2;
  font-size: 16px;
  font-weight: normal;
  color: $color-grey-32;
  width: 350px;
  box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.15);

  > ul {
    border-bottom: 1px solid #d2d5e2;
    padding-bottom: 10px;
    margin-bottom: 15px;

    li {
      padding: 15px 10px 15px 20px;
      margin: 5px auto;
    }

    &:first-child li {
      cursor: pointer;
      
      &:hover {
        background: $color-grey-E4;
      }
    }
  }

  .actions {
    margin: 15px auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 15px;

    button:first-child {
      color: $color-grey-32;
    }
  }
}
