@import '../../styles/variables';

.wrapper {
  display: grid;
  grid-template-columns: minmax(250px, 423px) minmax(550px, 1fr);
  max-width: 100%;
  overflow: hidden;
  font-weight: 500;
  height: $fixed-layout-height;
  position: fixed;
  top: $topnav-height;
  bottom: 0;
  left: 0;
  right: 0;

  > section {
    border-right: 1px solid #d2d5e2;
    position: relative;
  }
}
