@import '../../styles/variables';

.wrapper {
  padding: 20px;

  &:not(:first-child) {
    border-top: 1px solid #D2D5E2;
  }

  h3 {
    font-weight: 500;
    font-size: 16px;
    line-height: 134.4%;
    font-weight: 500;
    margin-bottom: 20px;
    font-size: 16px;
    line-height: 134.4%;
    color: $color-purple-92;
  }

  input {
    width: 363px;
  }

  button {
    margin-left: 20px;
  }
}