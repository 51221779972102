@import '../../../../styles/variables';

.wrapper {
  background: rgba(0, 0, 0, 0.13);
  position: fixed;
  right: 25vw;
  left: 0;
  z-index: 3;
  display: flex;
  justify-content: flex-end;
  height: calc(100vh - #{$queue-nav-height} - #{$topnav-height});

  .options {
    display: inline-block;
    position: relative;
    height: 100%;
    overflow-y: auto;
    min-width: 500px;
    padding: 15px 20px;
    background: $color-grey-F3;
    border: 1px solid $color-grey-D2;
    border-top: unset;

    > h3 {
      display: flex;
      align-items: center;
      justify-content: space-between;

      span {
        margin-right: 10px;
        cursor: pointer;
      }
    }

    > ul li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-weight: bold;
      padding: 0 2px 0 15px;
      background: $color-white;
      border: 1px solid $color-grey-D2;
      border-radius: 5px;
      margin-bottom: 8px;

      input {
        margin-right: 15px;
      }

      label {
        text-transform: capitalize;
        user-select: none;
      }

      .controls {
        display: flex;
        align-items: center;

        > span:not(.icon) {
          cursor: grab;
          display: inline-block;
          padding: 10px 6px;
          opacity: 0.6;
          width: 30px;

          img {
            margin: 0 auto;
          }

          &:hover {
            background: #f3f3f3;
            opacity: 0.9;
          }
        }

        .icon {
          position: relative;
          top: -2px;
          margin-right: 8px;
          opacity: 0.6;
          width: 20px;
          display: flex;
          justify-content: center;
        }
      }
    }
  }
}

@media screen and (max-width: 1400px) {
  .wrapper {
    right: 30vw;
  }
}

@media screen and (max-width: 1200px) {
  .wrapper {
    right: 40vw;
  }
}
