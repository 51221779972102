/* ------------------------------------------------------------ *\
	Variables
\* ------------------------------------------------------------ */

$font-roboto: 'Roboto', sans-serif;

$font-size-base: 15px;
$line-height-base: 1.5;

$topnav-height: 61px;
$livecalls-nav-height: 45px;
$queue-nav-height: 50px;
$queue-thead-height: 49px;
$fixed-layout-height: calc(100vh - #{$topnav-height});
$call-actions-height: 223.16px;

$responsive-sm: 1023px;
$responsive-md: 1200px;
$responsive-lg: 1350px;

$tablet-portrait: '(max-width: #{$responsive-sm}) ';
$small-desktop: '(max-width: #{$responsive-md}) ';
$large-desktop: '(max-width: #{$responsive-lg}) ';
$retina: '(min-resolution: 2dppx) ';

$color-white: #fff;
$color-grey-32: #32325d;
$color-grey-E4: #e4e4e4;
$color-grey-F3: #f3f4fa;
$color-grey-D2: #d2d5e2;

$color-blue-1C: #1c5289;
$color-blue-00: #007bc0;
$color-blue-6B: #6bb2da;
$color-blue-F0: #f0f7fb;
$color-blue-61: #61639B;

$color-purple-92: #9298bb;

$color-cream-FF: #ff978a;
$color-cream-F2: #f2994a;

$color-green-62: #62b08f;

$bg-primary: #2d9cdb;
$bg-danger: #eb5757;
$bg-success: #27ae60;
$bg-secondary: #5e72e4;
$bg-white: #e5e5e5;

$box-shadow-danger: 0 0 4px 0.2px #{$bg-danger}88;
