@import '../../styles/variables';

.wrapper {
  top: 0;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 33px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  section {
    text-align: center;
    position: relative;
    top: -60px;
  }
}
