@import '../../../../styles/variables';

.wrapper {
  height: $fixed-layout-height;
  position: relative;
  display: grid;
  grid-template-columns: 1fr 300px;
  grid-template-rows: 1fr;

  &.with-section-nav {
    height: calc(#{$fixed-layout-height} - #{$livecalls-nav-height} - 5px);
  }
}
