@import '../../../../styles/variables';

.wrapper {
  padding-right: 5px;
  font-weight: normal;
  overflow: hidden;
  height: auto;
  padding-bottom: 10px;
  padding-top: 10px;
  overflow-y: auto;
  padding-left: 15px;
  background: #{$color-grey-F3}E6;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  // 63px is the chat header height
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: calc(100% - #{$call-actions-height});
  z-index: 5;

  > h2 {
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  > button:nth-child(3) {
    color: $color-purple-92;
  }
}
