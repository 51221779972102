@import '../../../../styles/variables';

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 18px;
  height: calc(100% - #{$topnav-height});

  h3 {
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    margin-bottom: 20px;
  }

  p {
    color: $color-purple-92;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 16px;
  }

  .remove-call {
    position: absolute;
    bottom: 45px;
    text-align: center;
  }
}
