@import '../../styles/variables';

.wrapper {
  display: grid;
  grid-template-columns: 230px 1fr;
  height: 100%;
  line-height: 134.4%;

  .header {
    border-right: 1px solid #d2d5e2;
  }

  h2 {
    font-size: 16px;
    line-height: 134.4%;
    padding: 20px;
    border-bottom: 1px solid #d2d5e2;
  }

  li {
    padding: 20px;
    border-bottom: 1px solid #d2d5e2;
    cursor: pointer;
    display: flex;
    align-items: center;
    // justify-content: space-between;

    span {
      margin-right: 10px;

      &:last-child {
        // text-align: right;
      }
    }

    &.active {
      font-weight: 500;
      position: relative;
      background: $color-blue-F0;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        background: $color-blue-00;
        width: 10px;
      }
    }
  }

  .tab {
    display: none;

    &.active {
      display: block;
    }
  }
}
