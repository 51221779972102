@import '../../../../styles/variables';

.wrapper {
  padding: 14px 28px 16px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid $color-grey-D2;

  .owner {
    margin-left: 16px;

    > p:last-child {
      font-weight: 400;
    }
  }
}
