/* Layout */
.fixed-layout {
  max-width: 100%;
  overflow: hidden;
  height: $fixed-layout-height;
  position: fixed;
  top: $topnav-height;
  bottom: 0;
  left: 0;
  right: 0;
}

.flex-basis-30 {
  flex-basis: 30% !important;
  display: flex;
  justify-content: center;
}

.hide-scrollbars {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }
}

/*  Hidden  */
[hidden],
.hidden,
.is-hidden {
  display: none;
}

/* General */
.d-block {
  display: block;
}

.p-relative {
  position: relative;
}

.list-style-none {
  list-style: none;
}

.nowrap {
  white-space: nowrap;
}

.line-through {
  text-decoration: line-through;
}

.avatar-initial {
  display: inline-block;
  line-height: 35px;
  width: 35px;
  height: 35px;
  border-radius: 50px;
  color: $color-white;
  text-align: center;
  background: $bg-success;
  letter-spacing: -1px;
}

/* Loading/Error/Disabled */
[disabled],
.disabled {
  cursor: default;
  pointer-events: none;
  color: lighten($color-grey-32, 35%);
}

.inactive {
  opacity: 0.8;
  pointer-events: none;
  cursor: default;
}

/* Background */
.bg-danger {
  background: $bg-danger;
}
.bg-primary {
  background: $bg-primary;
}
.bg-success {
  background: $bg-success;
}
.bg-secondary {
  background: $bg-secondary;
}
.bg-white {
  background: $bg-white;
}

/* Text */
.text-error {
  color: $bg-danger !important;
  font-weight: bold;
  padding: 8px;
  font-size: 14px;
  border-radius: 5px;
  text-align: center;
  text-transform: none;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.text-italic {
  font-style: italic;
}

.text-secondary {
  opacity: 0.4;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

/* Borders */
.border-right {
  border-right: 1px solid #d2d5e2;
}

/* Modals */
.modal-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.13);
  backdrop-filter: blur(3px);
  z-index: 5;

  .modal {
    display: flex;
    padding: 30px;
    text-align: center;
    flex-direction: column;
    background: $color-white;
    border-radius: 5px;
    position: relative;
    top: -60px;
    box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034), 0 6.7px 5.3px rgba(0, 0, 0, 0.048),
      0 12.5px 10px rgba(0, 0, 0, 0.06), 0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086),
      0 100px 80px rgba(0, 0, 0, 0.12);

    > p {
      color: $color-grey-32;
      font-weight: bold;
      margin-bottom: 20px;
    }
  }
}

/*  Grid  */
.col {
  max-width: 100%;
  flex: 1;

  &s {
    display: flex;
    flex-flow: row wrap;
  }

  &--1of2 {
    max-width: 50%;
    flex: 0 0 50%;
  }
}

/* Text */
.text-capitalize {
  text-transform: capitalize;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-center {
  text-align: center;
}

.text-bold {
  font-weight: bold;
}

.text-white {
  color: #fff;
}

.text-success {
  color: $bg-success;
}

.text-primary {
  color: $bg-primary;
}

.text-invalid {
  color: $color-purple-92;
}

.text-danger {
  color: $bg-danger;
}

/* Inputs */
form input.invalid {
  box-shadow: inset 0 0 2px 1px $bg-danger;
}

.has-error {
  box-shadow: $box-shadow-danger !important;
}

.checkbox-container {
  position: relative;
  line-height: 25px;

  input[type='radio'],
  input[type='checkbox'] {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
    cursor: pointer;
    display: none;
  }

  .checkmark {
    position: absolute;
    top: 12px;
    left: 0;
    height: 25px;
    width: 25px;
    border: 2px solid $color-grey-D2;
    box-sizing: border-box;
    border-radius: 5px;
    cursor: pointer;
  }

  /* On mouse-over, add a grey background color */
  &:hover input ~ .checkmark {
    background: $color-grey-E4;
  }

  /* When the checkbox is checked, add a blue background */
  & input:checked ~ .checkmark {
    background: $color-purple-92;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  & input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  & .checkmark:after {
    left: 7px;
    top: 2px;
    width: 7px;
    height: 14px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  label {
    padding: 12px 3px;
    padding-left: 43px;
    display: block;
  }
}

/* Margins and paddings (8px multiplyier) */
.pt-0 {
  padding-top: 0;
}

.ml-2 {
  margin-left: 16px;
}

.ml-4 {
  margin-left: 32px;
}

.mr-1 {
  margin-right: 8px;
}

.mr-2 {
  margin-right: 16px;
}

.mr-4 {
  margin-right: 32px;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-2 {
  margin-bottom: 16px;
}

.mb-4 {
  margin-bottom: 32px;
}

.mt-2 {
  margin-top: 16px;
}

.mt-4 {
  margin-top: 32px;
}

.ml-auto {
  margin-left: auto;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.top-50 {
  top: 50px;
}

/* animations */
.fade-in {
  animation-name: fadeIn;
  animation-duration: 0.6s;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* utils */
.keyboardKey {
  background: $color-white;
  color: $color-grey-32;
  border-radius: 3px;
  border: 1px solid #d2d5e2;
  width: 25px;
  height: 20px;
  font-weight: bold;
  text-align: center;
  justify-content: center;
}

/* display flex/grid */
.d-flex-row {
  display: flex;
  align-items: bottom;
  height: auto;
  gap: 10px;
  margin: 0 auto !important;
}

/* forms */
.form-label {
  font-weight: 500;
  font-size: 16px;
  line-height: 134.4%;
  text-align: left;
}

.form-control {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  gap: 10px;

  input:disabled {
    opacity: 0.8;
    cursor: not-allowed;
    pointer-events: all;
  }
}

.form-input {
  background: #ffffff;
  border: 1px solid #d2d5e2;
  box-sizing: border-box;
  border-radius: 5px;
  width: 100%;
  color: $color-grey-32;
  font-size: 16px;
  padding: 10px 16px;

  &.form-input-md {
    min-width: 215px;
  }
}

select.form-input {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: window;
  background-image: url('../assets/images/angle_down.svg');
  background-repeat: no-repeat;
  background-position: 99% center;
}

input.focused,
div.focused {
  border: 1px solid $color-blue-00;
}

.custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
}

.custom-file-input::after {
  content: 'Browse Files';
  display: block;
  margin: 16px auto 0;
  color: $color-white;
  padding: 12px;
  width: 180px;
  border-radius: 5px;
  background: $color-purple-92;
  outline: none;
  white-space: nowrap;
  text-align: center;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
}

.custom-file-input:hover::after {
  border-color: black;
}

.custom-file-input:active::after {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}

.notification {
  .notification__item--success {
    background: $bg-success;
  }
}
