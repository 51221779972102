.wrapper {
  form {
    padding: 0 18px 18px;
    display: flex;
    flex-direction: column;
    height: calc(100% - 63px);

    .textareaContainer {
      width: 100%;
      height: auto;
      box-sizing: border-box;
      flex-grow: 1;
      display: flex;
      flex-direction: column;

      textarea {
        resize: none;
        flex-grow: 1;
        max-height: 330px;
      }

    }
  }
}