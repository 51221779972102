@import '../../styles/variables';

.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  background: $color-white;
  border: 1px solid #d2d5e2;
  box-sizing: border-box;
  border-radius: 5px;

  p {
    padding: 0;
    margin: 0;
  }

  .buttons {
    button:not(:last-child) {
      margin-right: 8px;
    }
  }
}
