@import '../../../../styles/variables';
.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  gap: 16px;
  font-size: 16px;
  line-height: 19px;

  [data-bulk-processing-container] {
    display: flex;
    position: relative;
    padding-left: 35px;
    margin-bottom: 22px;
    margin-top: 30px;
    max-width: 350px;
    cursor: pointer;
    border: none;
    user-select: none;

    /* Hide the browser's default checkbox */
    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    /* Create a custom checkbox */
    .checkmark {
      position: absolute;
      top: 2px;
      left: 0;
      height: 20px;
      width: 20px;
      border: 2px solid $color-grey-D2;
      box-sizing: border-box;
      border-radius: 5px;
      cursor: pointer;
    }

    /* On mouse-over, add a grey background color */
    &:hover input ~ .checkmark {
      background: $color-grey-E4;
    }

    /* When the checkbox is checked, add a blue background */
    & input:checked ~ .checkmark {
      background: $color-purple-92;
    }

    /* Create the checkmark/indicator (hidden when not checked) */
    .checkmark:after {
      content: '';
      position: absolute;
      display: none;
    }

    /* Show the checkmark when checked */
    & input:checked ~ .checkmark:after {
      display: block;
    }

    /* Style the checkmark/indicator */
    & .checkmark:after {
      left: 6px;
      top: 1px;
      width: 5px;
      height: 12px;
      border: solid white;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }

    & label {
      font-weight: 500;
      font-size: 1rem;
      line-height: 134.4%;
    }
  }
}
