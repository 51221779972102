@import '../../../../styles/variables';

.wrapper {
  height: 100%;

  .callDetails {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 9px;
    background: $color-white;
    border-bottom: 1px solid #d2d5e2;
    border-top: none;

    h1 {
      font-size: 20px;
      line-height: 134.4%;
    }
  }

  .chat {
    padding-left: 18px;
    padding-right: 5px;
    // 63px is the chat header height
    height: calc(100% - 63px);
    display: flex;
    flex-direction: column;
    align-items: stretch;
    position: relative;

    .messagesContainer {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      position: relative;
      height: 100%;
    }
  }
}
