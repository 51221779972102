@import '../../../../styles/variables';

.wrapper {
  li {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &.active {
      background: #{$color-grey-E4}77;
    }

    > * {
      pointer-events: none;
    }
  }
}
