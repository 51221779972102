@import '../../../../styles/variables';

.wrapper {
  font-weight: 500;
  background: $color-white;
  position: relative;
  height: 100%;
  border-right: 1px solid #d2d5e2;

  .details {
    // 73px is the button container
    height: calc(100vh - #{$topnav-height} - 73px - 55px);
    overflow-y: auto;
    position: relative;
    top: 55px;

    &.without-button {
      height: calc(100vh - #{$topnav-height} - 50px);
    }

    &.without-header {
      height: calc(100vh - #{$topnav-height} - 73px);
      top: 0;
    }

    &.has-page-nav {
      height: calc(100vh - #{$topnav-height} - 46px - 50px);
    }
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 20px;
    border-bottom: 1px solid #d2d5e2;
    height: 50px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background: inherit;
    z-index: 3;

    > * {
      cursor: default;
    }

    h2 {
      font-size: 20px;
      line-height: 1.2;
      padding: 12px 28px;
      font-weight: 500;
      max-width: 80%;

      span {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    > span {
      text-transform: uppercase;
    }
  }

  .claim {
    font-size: 16px;
    line-height: 134.4%;
    color: $color-purple-92;

    summary {
      cursor: pointer;

      h3 {
        font-size: 16px;
        line-height: 134.4%;
        padding: 13px 28px 0;
        color: $color-purple-92;

        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }

    ul {
      padding: 14px 28px 16px;
      border-bottom: 1px solid #d2d5e2;

      li {
        color: $color-grey-32;
        padding-top: 11px;
        padding-bottom: 11px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        span {
          font-weight: normal;
        }
      }
    }
  }

  .buttonContainer {
    text-align: center;
    padding: 14px 28px 20px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
