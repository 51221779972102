@import '../../../../styles/variables';

.wrapper {
  // 60px is the header height
  height: calc(#{$fixed-layout-height} - 60px);
  overflow: auto;
  font-size: 16px;

  h3 {
    font-size: 16px;
    line-height: 134.4%;
    padding: 20px;
  }

  h4 {
    font-weight: 400;
  }

  form {
    padding: 20px;

    textarea {
      width: 100%;
      height: 142px;
      resize: none;
    }

    .options {
      .radio-container {
        position: relative;
        line-height: 25px;

        input[type='radio'] {
          position: absolute;
          opacity: 0;
          width: 0;
          height: 0;
          cursor: pointer;
          display: none;
        }

        .checkmark {
          position: absolute;
          top: 12px;
          left: 0;
          height: 25px;
          width: 25px;
          border: 2px solid $color-grey-D2;
          box-sizing: border-box;
          border-radius: 5px;
          cursor: pointer;
        }

        /* On mouse-over, add a grey background color */
        &:hover input ~ .checkmark {
          background: $color-grey-E4;
        }

        /* When the checkbox is checked, add a blue background */
        & input:checked ~ .checkmark {
          background: $color-purple-92;
        }

        /* Create the checkmark/indicator (hidden when not checked) */
        .checkmark:after {
          content: '';
          position: absolute;
          display: none;
        }

        /* Show the checkmark when checked */
        & input:checked ~ .checkmark:after {
          display: block;
        }

        /* Style the checkmark/indicator */
        & .checkmark:after {
          left: 7px;
          top: 2px;
          width: 7px;
          height: 14px;
          border: solid white;
          border-width: 0 3px 3px 0;
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
        }

        label {
          padding: 12px 3px;
          padding-left: 43px;
          display: block;
        }
      }
    }
  }
}
