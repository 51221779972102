.wrapper {
  opacity: 0.5;
  cursor: pointer;
  margin-left: 5px;

  * {
    transition: all 0.2s ease-in-out;
  }

  svg {
    position: relative;
    top: 4px;
  }

  &:hover {
    opacity: 0.9;
  }

  &.active {
    opacity: 1;
    transform: scale(1.05);

    svg {
      circle {
        fill: #27AE60;
      }
    }
  }
}
