@import '../../../../styles/variables';

.wrapper {
  display: flex;
  flex-direction: column;
  height: calc(100vh - #{$topnav-height});

  .nav {
    overflow-x: auto;
    z-index: 2;

    ul {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: nowrap;
      font-size: 16px;
      line-height: 134.4%;
      background: $color-grey-F3;
      border-bottom: 1px solid #d2d5e2;
      box-sizing: border-box;

      li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        white-space: nowrap;
        padding: 11px;
        border-right: 1px solid #d2d5e2;
        min-width: 230px;
        gap: 15px;
        background: $color-white;

        &:not(.active):hover {
          cursor: pointer;
          color: darken($color-grey-32, 30%);
          background: $color-white;
        }

        &.active {
          position: relative;
          top: 1px;
        }

        span {
          display: flex;
          align-items: center;
        }

        .status {
          width: 16px;
          height: 16px;
          background: $bg-success;
          display: inline-block;
          border-radius: 50px;
          margin-right: 6px;

          &.danger {
            background: $bg-danger;
          }
        }
      }
    }
  }

  .callDisplay {
    display: grid;
    grid-template-columns: minmax(250px, 423px) minmax(550px, 1fr);
    max-width: 100%;
    overflow: hidden;
    font-weight: 500;
    height: calc(100vh - #{$topnav-height} - #{$livecalls-nav-height});
  }
}
